import { usersApi } from "@/api";
import ModalComponent from "@/components/Modal";
import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import { CallStatsComponent } from "./components/call-stats.component";

interface Props {
  userId: number;
  onClose: () => void;
}

export const CallStatsModal: FC<Props> = ({ userId, onClose }) => {
  const [data, setData] = useState<any>();

  const load = async () => {
    const { data } = await usersApi.getUserCallStats(userId);
    setData(data);
  };

  useEffect(() => {
    if (userId) {
      load();
    } else {
      setData(null);
    }
  }, [userId]);

  return (
    <ModalComponent
      title="Статистика дзвінків"
      show={Boolean(userId)}
      toggle={onClose}
    >
      <CallStatsComponent data={data} />
    </ModalComponent>
  );
};
