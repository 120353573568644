import React, { FC } from "react";

interface Props {
  data: any;
}

const labelsByLogs = {
  "Send ice candidates": "Відправка ice кандидатів",
  "Send rtc session description": "Відправка сесії",
  "Call updated to status connecting": "Дзвінок оновлено до статусу зєднання",
  "Finished call, reason failedConnect":
    "Дзвінок завершено з статусом невдалий",
  "Start call": "Початок дзвінка",
};

export const CallStatsComponent: FC<Props> = ({ data }) => {
  const renderByLogs = () => {
    if (!data || !data.failedByLastLog) return null;

    const stats = data.failedByLastLog;
    const logs = Object.keys(stats);

    return logs.map((it) => {
      const label = labelsByLogs[it] || it;

      const byOs = stats[it].byOs;

      return (
        <div key={it}>
          <p>
            {label}: {stats[it]?.count}
          </p>
          {byOs ? (
            <div className="callstats-detailed-item">
              {Object.keys(byOs).map((key) => {
                return (
                  <p key={key}>
                    {key}: {byOs[key]}
                  </p>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    });
  };

  const renderByOs = () => {
    if (!data || !data.failedByOs) return null;

    const stats = data.failedByOs;
    return Object.keys(stats).map((key) => {
      const label = key === "undefined" ? "Не відомо" : key;
      return (
        <p className="callstats-item" key={key}>
          {label}: {stats[key]}
        </p>
      );
    });
  };

  const renderByPeerStatus = () => {
    if (!data || !data.byPeerStatus) return null;

    return Object.keys(data?.byPeerStatus).map((key) => {
      const label = key === "undefined" ? "Не відомо" : key;
      return (
        <p className="callstats-item" key={key}>
          {label}: {data.byPeerStatus[key]}
        </p>
      );
    });
  };

  const renderByDevices = () => {
    if (!data || !data.byMetadata) return;

    const stats = data.byMetadata;
    const params = Object.keys(stats);

    return params.map((param) => {
      const values = stats[param];

      return (
        <div key={param}>
          <p>{param}</p>

          <div className="callstats-detailed-item">
            {Object.keys(values).map((key) => {
              return (
                <p key={key}>
                  {key}: <b>{values[key]}</b>
                </p>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="callstats-section">
        <p className="callstats-title">По статусах</p>
        <p className="callstats-item">
          Успішниx викликів: {data?.statuses?.endcall}
        </p>
        <p className="callstats-item">
          Відхилених викликів: {data?.statuses?.rejected}
        </p>
        <p className="callstats-item">
          Скасованих викликів: {data?.statuses?.canceled}
        </p>
        <p className="callstats-item">
          Невдалих викликів: {data?.statuses?.failedConnect}
        </p>
      </div>

      <div className="callstats-section">
        <p className="callstats-title">Невдалі виклики по останніх логах</p>

        {renderByLogs()}
      </div>

      <div className="callstats-section">
        <p className="callstats-title">Невдалі виклики по OS</p>

        {renderByOs()}
      </div>

      <div className="callstats-section">
        <p className="callstats-title">Невдалі виклики по peer статусу</p>

        {renderByPeerStatus()}
      </div>

      <div className="callstats-section">
        <p className="callstats-title">
          Невдалі виклики по параметрах девайсів
        </p>

        {renderByDevices()}
      </div>
    </div>
  );
};
