import { getProfile } from "@/store/account";
import { Col, Container, Row, Button } from "reactstrap";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { match } from "react-router";
import { useContact } from "../Contact/hooks/use-contact.hook";
import { ProfileMain, ProfileTabs } from "../Profile/components";
import "./style.scss";
import { useUserActions } from "../Profile/hooks";
import {
  checkPermission,
  ConfirmModal,
  EUserRole,
  Permissions,
} from "@/shared";
import { getMyPermissions } from "@/store/permissions";
import { CallStatsModal } from "@/smart-components/call-stats-modal";
import { CallStatsGlobalModal } from "@/smart-components/call-stats-modal/call-stats-global.modal";

interface IProps {
  match: match<{ id: string }>;
}

export const UserDetail: FC<IProps> = ({ match: { params: id } }) => {
  const profile = useSelector(getProfile);
  const permissions = useSelector(getMyPermissions);
  const [isStatsOpen, setOpenStats] = useState(false);
  const [isGbStatsOpen, setGbOpenStats] = useState(false);

  const contactId = parseInt(id.id);
  const {
    contactInfo,
    fetchContact,
    contactStats,
    contactTasksCount,
  } = useContact(contactId);

  const { confirmModal, onPressAction } = useUserActions({
    user: contactInfo,
    onAction: fetchContact,
  });

  const isUpdated = checkPermission(
    "tabs",
    "user",
    profile,
    permissions,
    Permissions.UPDATE
  );

  return contactInfo ? (
    <Container>
      <ConfirmModal
        isShow={confirmModal.state.isOpen}
        message={confirmModal.state.message}
        onConfirm={confirmModal.state.onConfirm}
        setShow={(state: boolean) =>
          confirmModal.set({ ...confirmModal.state, isOpen: state })
        }
      />
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain
                isUpdate={isUpdated}
                profile={contactInfo}
                authProfile={profile}
                countStats={contactStats}
                countTasksStats={contactTasksCount}
                onPressUserAction={onPressAction}
                onChangeAvatar={fetchContact}
                onDeleteAvatar={fetchContact}
              />
            </Row>
          </Col>

          <ProfileTabs
            isUpdate={isUpdated}
            profile={contactInfo}
            authProfile={profile}
            onUpdate={fetchContact}
          />
        </Row>
        {profile?.role === EUserRole.Admin ? (
          <>
            <Row>
              <Button color="primary" onClick={() => setOpenStats(true)}>
                Статистика по дзвінках
              </Button>
              <Button color="primary" onClick={() => setGbOpenStats(true)}>
                Загальна статистика по дзвінках
              </Button>
            </Row>
            <CallStatsModal
              userId={isStatsOpen ? contactInfo.id : null}
              onClose={() => setOpenStats(false)}
            />
            <CallStatsGlobalModal
              isOpen={isGbStatsOpen}
              onClose={() => setGbOpenStats(false)}
            />
          </>
        ) : null}
      </div>
    </Container>
  ) : null;
};
