import { usersApi } from "@/api";
import ModalComponent from "@/components/Modal";
import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import { CallStatsComponent } from "./components/call-stats.component";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const CallStatsGlobalModal: FC<Props> = ({ onClose, isOpen }) => {
  const [data, setData] = useState<any>();

  const load = async () => {
    const { data } = await usersApi.getUsersCallStats();
    setData(data);
  };

  useEffect(() => {
    if (isOpen) load();
    else setData(null);
  }, [isOpen]);

  return (
    <ModalComponent
      title="Статистика дзвінків"
      show={Boolean(isOpen)}
      toggle={onClose}
    >
      <CallStatsComponent data={data} />
    </ModalComponent>
  );
};
